import { useContext } from 'react';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Button, Icon } from '..';
import { TOPICS, TopicProps } from '../../data/topics';
import { HistoryContext } from '../../providers';

export const TopicNext = ({ currentTopic }: { currentTopic: TopicProps }) => {
  const { progress, orderedTopics } = useContext(HistoryContext);
  const uncompletedTopics = orderedTopics.filter((topic) => {
    const completedSections = progress.filter((e) => e.section.startsWith(topic.answerKey)).length;
    const totalSections = TOPICS[topic.id].sections.length;

    return completedSections !== totalSections && totalSections > 0;
  });
  const otherUncompletedTopics = uncompletedTopics.filter((topic) => {
    return currentTopic.id !== topic.id;
  });

  const isCurrentTopicComplete = uncompletedTopics
    .map((topic) => topic.id as string)
    .includes(currentTopic.id as string);

  const completedSections = progress.filter((e) =>
    e.section.startsWith(orderedTopics.find((topic) => topic.id === currentTopic.id)?.answerKey as string),
  ).length;
  const totalSections = TOPICS[currentTopic.id].sections.length;

  // const [promoOpen, setPromoOpen] = useState(false);
  // const onHandlePromoDialogOpen = () => {
  //   setPromoOpen(true);
  // };
  // const onHandlePromoDialogClose = () => {
  //   setPromoOpen(false);
  // };

  // let promo: ReactNode = null;
  // if (!isCurrentTopicComplete) {
  //   promo = <PromoDialogCta onClick={onHandlePromoDialogOpen} />;
  // }

  return (
    <div id="section-next" className="min-h-screen flex flex-col gap-12 justify-center items-center p-4 snap-start">
      <Link to="/trophies">
        <Button type="primary" disabled={isCurrentTopicComplete}>
          {isCurrentTopicComplete ? (
            <>
              {completedSections}/{totalSections} of {currentTopic.title}.
              <br />
              Please complete the quiz to win a trophy.
            </>
          ) : (
            <>You have earned the &ldquo;{currentTopic.title}&rdquo; trophy</>
          )}
        </Button>
      </Link>
      {/* 
      {promo}
      {promoOpen && <PromoDialog onClose={onHandlePromoDialogClose} />} */}
      <hr className="w-full max-w-3xl" />

      {otherUncompletedTopics.length > 0 ? (
        <>
          <h3 className="text-3xl">Where do you want to go next?</h3>
          <div className="flex flex-col md:flex-row gap-12 justify-between items-center">
            {otherUncompletedTopics.slice(0, 2).map((topic) => (
              <HashLink to={`/topic/${topic.id}#`} key={topic.id}>
                <div className="group flex flex-col justify-center items-center gap-4">
                  <div className="w-48 aspect-square border-2 border-white rounded-full flex justify-center items-center group-hover:bg-primary-1">
                    <div className="w-24 aspect-square fill-white">
                      <Icon icon={topic.id} />
                    </div>
                  </div>
                  <h4 className="text-xl">{topic.name}</h4>
                </div>
              </HashLink>
            ))}
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center gap-4">
          <h3 className="text-3xl">Congratulations!</h3>
          <p>
            You have completed all of the Topics! Take a look at all of your progress in the{' '}
            <HashLink to="/trophies#" className="underline">
              Trophy Room
            </HashLink>
            .
          </p>
        </div>
      )}
    </div>
  );
};
