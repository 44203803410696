import { PropsWithChildren, useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Home, Icon, IconKeys, TopicPage, TrophyRoom, WelcomeBack } from './components';
import { TopicKeys } from './data/topics';
import { HistoryContext, HistoryProvider } from './providers';

export default function App() {
  return (
    <BrowserRouter>
      <AnalyticsProvider>
        <HistoryProvider>
          <RouterChoice />
        </HistoryProvider>
      </AnalyticsProvider>
    </BrowserRouter>
  );
}

const AnalyticsProvider = (props: PropsWithChildren<Record<never, never>>) => {
  const location = useLocation();
  useEffect(() => {
    // @ts-expect-error gtag isn't globally typed
    gtag('set', 'page_path', location.pathname + location.search + location.hash);
    // @ts-expect-error gtag isn't globally typed
    gtag('event', 'page_view');
  }, [location.pathname, location.search, location.hash]);
  return <>{props.children}</>;
};

const RouterChoice = () => {
  return <ReturningUserRouter />;
};

const ReturningUserRouter = () => (
  <>
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/code" element={<WelcomeBack />} />
      <Route path="/topic/:topicSlug" element={<TopicPage />} />
      <Route path="/trophies" element={<TrophyRoom />} />
      {/* <Route path="/my-fitness-pal-thank-you" element={<MyFitnessPalThankYou />} /> */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
    <GameMenus />
  </>
);

const GameMenus = () => (
  <div className="fixed right-0 top-24 flex flex-col gap-2.5 z-50">
    <HeartPoints />
    <MapLink />
  </div>
);

const HeartPoints = () => {
  const { progress } = useContext(HistoryContext);
  const [points, setPoints] = useState(0);
  const initialPoints = 0;
  useEffect(() => {
    setPoints(progress.map((e) => e.points).reduce((prev, curr) => prev + curr, initialPoints));
  }, [progress]);
  return (
    <HashLink to="/trophies#" aria-label="Trophies">
      <div
        className={clsx(
          'w-[4rem] pl-2 pr-2 py-2 text-xl',
          'md:w-[5rem] md:pl-4 md:pr-6 md:py-3 md:text-2xl',
          'bg-secondary-2 text-white rounded-l-xl flex flex-col gap-1 justify-center items-center font-loos leading-none shadow-xl',
          'translate-x-2 hover:translate-x-0 transition cursor-pointer select-none',
        )}
      >
        <div className="w-4 md:w-6">
          <Icon icon={IconKeys.HEART_POINT} />
        </div>
        {points}
      </div>
    </HashLink>
  );
};

const MapLink = () => (
  <HashLink to="/#" aria-label="Home">
    <div
      className={clsx(
        'w-[4rem] pl-2 pr-2 py-2 text-xl',
        'md:w-[5rem] md:pl-4 md:pr-6 md:py-3',
        'bg-primary-1 text-white translate-x-2 hover:translate-x-0 transition cursor-pointer select-none rounded-l-xl flex flex-col justify-center items-center shadow-xl',
      )}
    >
      <div className="w-6 md:w-7">
        <Icon icon={IconKeys.MAP} />
      </div>
    </div>
  </HashLink>
);

export interface SubjectProps {
  id: TopicKeys;
  name: string;
  choice: string;
  color: string;
  answerKey: string;
}
export const SUBJECTS: SubjectProps[] = [
  {
    id: TopicKeys.BLOOD_PRESSURE,
    name: 'Blood Pressure',
    choice: 'Control my blood pressure',
    color: 'bg-primary-1',
    answerKey: 'bp',
  },
  {
    id: TopicKeys.MOVE_MORE,
    name: 'Move More',
    choice: 'Move more',
    color: 'bg-primary-2',
    answerKey: 'mm',
  },
  {
    id: TopicKeys.CHOLESTEROL,
    name: 'Cholesterol',
    choice: 'Manage my cholesterol',
    color: 'bg-primary-2 md:bg-primary-1',
    answerKey: 'ch',
  },
  {
    id: TopicKeys.EAT_HEALTHY,
    name: 'Eat Healthy',
    choice: 'Eat healthier',
    color: 'bg-primary-1 md:bg-primary-2',
    answerKey: 'eh',
  },
  {
    id: TopicKeys.STRESS_LESS,
    name: 'Stress Less',
    choice: 'Stress less',
    color: 'bg-primary-1 md:bg-primary-2',
    answerKey: 'sl',
  },
  {
    id: TopicKeys.WORK_WITH_A_DOCTOR,
    name: 'Work with a Doctor',
    choice: 'Work with a doctor',
    color: 'bg-primary-2 md:bg-primary-1',
    answerKey: 'wd',
  },
  {
    id: TopicKeys.BLOOD_SUGAR,
    name: 'Blood Sugar',
    choice: 'Manage my blood sugar',
    color: 'bg-primary-2',
    answerKey: 'bs',
  },
  {
    id: TopicKeys.QUIT_SMOKING,
    name: 'Quit Smoking',
    choice: 'Quit Smoking',
    color: 'bg-primary-1',
    answerKey: 'qs',
  },
];
