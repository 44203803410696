import { useState } from 'react';

import { Dialog } from '@headlessui/react';
import { AxiosError } from 'axios';
import clsx from 'clsx';

import { Api } from '../../api';
import { ReactComponent as CloseIcon } from '../../assets/icon-close.svg';

const RecaptchaReady = new Promise<boolean>((resolve) => {
  grecaptcha.enterprise.ready(async () => {
    resolve(true);
  });
});

export interface PromoDialogProps {
  onClose: () => void;
}
export const PromoDialog = (props: PromoDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      setLoading(true);
      setErrorMessage(undefined);
      await RecaptchaReady;
      const token = await grecaptcha.enterprise.execute(process.env.REACT_APP_GOOGLE_CAPTCHA_KEY!, {
        action: 'SUBMIT',
      });
      await Api.formSubmission.myFitnessPal({
        EMAIL: formData.get('email') as string,
        FNAME: formData.get('firstName') as string,
        LNAME: formData.get('lastName') as string,
        token,
      });
      setSuccessMessage('Thanks! You will receive your code in 2-3 business days.');
    } catch (e) {
      const axiosError = e as AxiosError<{ error: string }>;
      if (axiosError.response?.data?.error) {
        setErrorMessage(axiosError.response?.data?.error);
      } else {
        setErrorMessage('An unknown error has occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const disabled = loading || !!successMessage;

  const content = (
    <>
      <img src={require('../../assets/logo-myfitnesspal.png')} />

      <Dialog.Title className="my-8 text-center text-3xl font-normal">
        Get Your 1-Year MyFitnessPal Premium Membership
      </Dialog.Title>

      <div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          {!successMessage && (
            <>
              <div className="flex flex-col gap-1">
                <label htmlFor="firstName" className="font-bold">
                  First Name <span className="text-secondary-2">*</span>
                </label>
                <input id="firstName" name="firstName" required disabled={disabled} className="border p-2" />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="lastName" className="font-bold">
                  Last Name <span className="text-secondary-2">*</span>
                </label>
                <input id="lastName" name="lastName" required disabled={disabled} className="border p-2" />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="email" className="font-bold">
                  Email <span className="text-secondary-2">*</span>
                </label>
                <input id="email" name="email" type="email" required disabled={disabled} className="border p-2" />
              </div>
              <div className="flex items-center mt-2 mb-4">
                <button
                  type="submit"
                  disabled={disabled}
                  className="inline mx-auto rounded-full text-white uppercase font-bold disabled:opacity-75 text-2xl font-loos leading-none bg-gradient-to-b from-gradient-start to-gradient-stop rounded-bl-none px-12 pt-3 pb-4"
                >
                  Submit
                </button>
              </div>
            </>
          )}

          {errorMessage && (
            <div className="mb-4 p-4 bg-secondary-2 bg-opacity-10 text-center text-secondary-2">{errorMessage}</div>
          )}

          {successMessage && <div className="mb-4 p-4 bg-neutral-2 text-center">{successMessage}</div>}
        </form>
      </div>

      <p className="text-sm text-center">
        Valid only in the United States. Please allow up to 2-3 business days for receipt. While supplies last. Your
        email address will only be used for distribution of your unique redemption code and will not be shared with any
        third parties. Offer and participation is limited to new MyFitnessPal Premium customers only. No credit card
        required for redemption. Offer expires 12/31/2025. For full terms and conditions visit{' '}
        <a href="https://www.myfitnesspal.com/terms-of-service" target="_blank" className="text-black">
          https://www.myfitnesspal.com/terms-of-service
        </a>
        .
      </p>
    </>
  );
  const closeButton = (
    <button
      type="button"
      onClick={props.onClose}
      aria-label="Close dialog"
      className="absolute -top-2 -right-2 flex items-center justify-center w-12 h-12 bg-white hover:bg-gray-100 text-black rounded-full shadow-lg transition-all"
    >
      <CloseIcon />
    </button>
  );

  return (
    <Dialog open onClose={() => props.onClose()}>
      <div className="fixed inset-0 z-[60] bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 z-[61] w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className={clsx('relative mx-auto max-w-lg rounded bg-white text-black', 'pb-4 px-8 pt-16')}>
            {content}
            {closeButton}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export interface PromoDialogCta {
  onClick: () => void;
}

// export const PromoDialogCta = (props: PromoDialogCta) => {
//   return (
//     <div className="mx-auto max-w-xl text-center">
//       <img className="inline-block w-3/4 h-auto" src={require('../../assets/logo-myfitnesspal.png')} />
//       <p className="mt-4 mb-8 text-2xl font-bold">
//         Congratulations! You've earned a FREE 1-year membership to MyFitnessPal Premium! Click below to redeem your
//         membership.
//         <br />
//         <small className="text-sm">(Valid only in the United States)</small>
//       </p>
//       <button
//         type="button"
//         className="max-w-md px-8 py-4 border-2 border-white text-2xl font-bold bg-black hover:bg-white text-white hover:text-black transition-colors"
//         onClick={props.onClick}
//       >
//         Get One Year of MyFitnessPal Premium FREE
//       </button>
//     </div>
//   );
// };
