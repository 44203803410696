import { get, post, put } from './client';
import { CompletedEvent } from '../providers';

type UserPreference = { onboarded: boolean; interests: string[] };

export type UserNewParams = {
  progress: CompletedEvent[];
  preferences: UserPreference;
};

export type UserFetchParams = {
  codename: string;
};

export type UserUpdateParams = Omit<UserNewParams, 'preferences'> & UserFetchParams;

export type User = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  codename: string;
  progress: CompletedEvent[];
  preferences: UserPreference;
};

export interface MailchimpPromoParams {
  FNAME: string;
  LNAME: string;
  EMAIL: string;
  token: string;
}

export const Api = {
  user: {
    new: (params: UserNewParams) => {
      return post<{ data: User }, UserNewParams>(`/user`, params);
    },
    fetch: (params: UserFetchParams) => {
      return get<{ data: User }>(`/user/${params.codename}`);
    },
    update: (params: UserUpdateParams) => {
      const { codename, ...rest } = params;
      return put<{ data: User }, Omit<UserUpdateParams, 'codename'>>(`/user/${codename}`, rest);
    },
  },
  formSubmission: {
    myFitnessPal: (params: MailchimpPromoParams) => {
      return post<unknown, MailchimpPromoParams>(`/submission`, params);
    },
  },
};
