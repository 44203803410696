import { Fragment, useContext, useMemo } from 'react';

import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { CodeForm, Icon, IconKeys, Navbar, SvgElement } from '..';
import { TOPICS } from '../../data/topics';
import { HistoryContext } from '../../providers';

export const Home = () => {
  const { orderedTopics, progress } = useContext(HistoryContext);

  const finishedSections = useMemo(() => {
    const finishedSections = new Set<string>();

    const unfinishedSections = new Set<string>();
    // First add all sections
    for (const topicId in TOPICS) {
      for (const section of TOPICS[topicId].sections) {
        unfinishedSections.add(section.id);
      }
    }
    // Then remove the finished sections
    for (const section of progress) {
      unfinishedSections.delete(section.section);
    }

    // Grab all unique prefixes to the section ids that are unfinished
    const unfinishedPrefixes = new Set(Array.from(unfinishedSections).map((x) => x.split('-')[0]));

    for (const topicId in TOPICS) {
      if (!unfinishedPrefixes.has(TOPICS[topicId].sectionPrefix)) {
        finishedSections.add(TOPICS[topicId].id);
      }
    }

    return finishedSections;
  }, [progress]);

  // const [promoOpen, setPromoOpen] = useState(false);
  // const onHandlePromoDialogOpen = () => {
  //   setPromoOpen(true);
  // };
  // const onHandlePromoDialogClose = () => {
  //   setPromoOpen(false);
  // };
  // let promo: ReactNode = null;
  // if (finishedSections.size > 0) {
  //   promo = (
  //     <div className="mb-12">
  //       <PromoDialogCta onClick={onHandlePromoDialogOpen} />
  //       <hr className="mt-12" />
  //     </div>
  //   );
  // }

  return (
    <>
      <Helmet>
        <title>Live to the Beat | Take Charge!</title>
        <meta name="description" content="Take the first step to improving your heart health with Pulse Check" />
        <meta property="og:title" content="Live to the Beat | Take Charge!" />
        <meta property="og:description" content="Take the first step to improving your heart health with Pulse Check" />
        <meta property="og:image" content={`${process.env.REACT_APP_PULSE_CHECK_URL}/images/hero-onboarding.jpg`} />
        <meta property="og:url" content={process.env.REACT_APP_PULSE_CHECK_URL} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="relative bg-cover bg-center bg-[url('../public/images/hero-onboarding.jpg')] text-white">
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <Navbar />
        <div className="relative px-4 pt-8 md:pt-40 flex flex-col justify-between">
          <div></div>
          <div className="md:max-w-5xl mx-auto">
            <p className="bg-white w-60 xs:w-72 md:w-3/5 text-black p-4 rounded-tl-2xl rounded-br-2xl text-lg md:text-3xl shadow-xl">
              Everyone’s journey to better heart health is unique. It’s about figuring out what works best for you.
              Let’s get started.
            </p>
            <h1 className="text-6xl md:text-8xl font-bold md:w-5/6 mt-8 text-shadow-dk">
              It’s about living to your beat.
            </h1>
          </div>
          <div className="text-center pb-4 md:pb-10 pt-12 md:pt-24">
            <Link to="/code" className="underline">
              Already have a code name?
            </Link>
          </div>
        </div>
      </div>

      <div className="p-4 pt-10 max-w-5xl mx-auto">
        <h2 className="text-3xl mt-6 mb-12 text-center">Where do you want to jump in?</h2>
        <div className="relative">
          <div className="hidden lg:block absolute inset-16">
            <SvgElement type="map-bg-curve" />
          </div>
          <ul className="topic-map relative lg:grid lg:grid-cols-6 lg:gap-y-8 max-w-sm lg:max-w-full mx-auto list-none">
            {orderedTopics.map((subject, index) => {
              const MapItem = () => (
                <li
                  key={subject.id}
                  className={clsx('col-span-2', {
                    'order-1': index === 0,
                    'order-2': index === 1,
                    'order-3': index === 2,
                    'order-4 lg:order-5': index === 3,
                    'order-5 lg:order-4': index === 4,
                    'order-6': index === 5,
                    'order-7': index === 6,
                    'order-8': index === 7,
                  })}
                >
                  <Link
                    to={`/topic/${subject.id}`}
                    className="relative z-10 flex items-center lg:flex-col gap-4 pb-4 no-underline text-white group"
                  >
                    <div className="w-[6.25rem] lg:w-[9.25rem] aspect-square border lg:border-2 border-white bg-black group-hover:bg-primary-1 rounded-full flex justify-center items-center transition-colors duration-300">
                      <div className="w-16 aspect-square fill-white">
                        <Icon icon={subject.id} />
                      </div>
                    </div>
                    <h3 className="flex-1 flex items-center gap-2">
                      {finishedSections.has(subject.id) && (
                        <div className="w-8 aspect-square fill-primary-2">
                          <Icon icon={IconKeys.CIRCLE_CHECK} />
                        </div>
                      )}
                      <span>{subject.name}</span>
                    </h3>
                    <div className="lg:hidden text-4xl leading-none">&rsaquo;</div>
                  </Link>
                </li>
              );

              const Space = () => <li className="hidden lg:block lg:col-span-1 order-4">&nbsp;</li>;

              if (index === 3) {
                return (
                  <Fragment key={index}>
                    <Space />
                    <MapItem />
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={index}>
                    <MapItem />
                  </Fragment>
                );
              }
            })}
          </ul>
        </div>

        <div className="mt-24 mb-10">
          {/* {promo}
          {promoOpen && <PromoDialog onClose={onHandlePromoDialogClose} />} */}
          <CodeForm />
        </div>
      </div>
    </>
  );
};
